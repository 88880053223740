import { TableMessage } from '@/shared/empty-table-messages';
import {
  buildFilterPayload,
  handleErrorMessage,
  humanReadableActiveCaregiver,
  humanReadablePatientStatus,
  PatientSortCol,
  SortDir,
} from '@/shared';
import { dateFormatter } from '../../../../../shared/date-formatter';
import { createProjector } from '@conversa/sink';
import * as models from '../models';
import { PatientsTableHeaders } from '../models';
import { capabilitiesStateKey } from '@/capabilities/+state/store';
import { CapabilitiesState } from '@conversa/bedazzled/src/models/capabilities-state.interface';
export interface PatientsProjectionsMap {
  'patients.count': number;
  'patients.error-message': string;
  'patients.filters-dirty': boolean;
  'patients.filters': models.PatientsFilters;
  'patients.loading': boolean;
  'patients.page-number': number;
  'patients.search-payload': models.PatientSearchFiltersPayload;
  'patients.sort-col': PatientSortCol;
  'patients.sort-dir': SortDir;
  'patients.table': models.PatientsTable;
  'patients.tableMessage': TableMessage;
  'patients.tableHeaders': models.PatientsTableHeaders;
}

const PATIENT_TABLE_HEADERS = [
  { value: 'createdAt', text: 'Created', sortable: true },
  {
    value: 'patientName',
    text: 'Patient',
    mobileContentOverride: 'ID',
    sortable: true,
  },
  { value: 'providerName', text: 'Provider', sortable: true },
  { value: 'patientStatus', text: 'Patient Status', sortable: false },
  { value: 'language', text: 'Preferred Language', sortable: false },
  { value: 'activeCaregiver', text: 'Active Caregiver', sortable: false },
];

const projector = createProjector<
  PatientsProjectionsMap,
  models.PatientsState
>();

export const tableMessage = projector<TableMessage>(
  'patients.tableMessage',
  ({ store }) => store.tableMessage,
);

export const count = projector<number>(
  'patients.count',
  ({ store }) => store.count,
);

export const patientsStoreErrorMessage = projector<string>(
  'patients.error-message',
  handleErrorMessage,
);

export const filtersDirty = projector<boolean>(
  'patients.filters-dirty',
  ({ store }) => store.filtersDirty,
);

export const patientsFilters = projector<models.PatientsFilters>(
  'patients.filters',
  ({ store }) => store.filters,
);

export const loadingPatients = projector<boolean>(
  'patients.loading',
  ({ store }) => store.loading,
);

export const pageNumber = projector<number>(
  'patients.page-number',
  ({ store }) => store.page,
);

export const tableHeaders = projector<PatientsTableHeaders>(
  'patients.tableHeaders',
  ({ select }) => {
    const columnsToRemove = [];
    !select('capabilities.features.caregiver').value &&
      columnsToRemove.push('activeCaregiver');
    !select('capabilities.enabledLanguages.hasMultiple').value &&
      columnsToRemove.push('language');

    return PATIENT_TABLE_HEADERS.filter(
      h => !columnsToRemove.includes(h.value),
    );
  },
);

export const patientsSearchFilters = projector<
  models.PatientSearchFiltersPayload
>('patients.search-payload', ({ store, select }) => {
  const map = new Map<keyof models.PatientsFilters, string>([
    ['activeCaregiver', 'has_active_caregiver'],
    ['clinicPatientCode', 'clinic_patient_code'],
    ['createdAtFrom', 'utc_date_from'],
    ['createdAtTo', 'utc_date_to'],
    ['email', 'patient_email'],
    ['firstName', 'first_name'],
    ['language', 'preferred_language'],
    ['lastName', 'last_name'],
    ['patientStatus', 'patient_status'],
    ['phone', 'patient_mobile_phone'],
    ['providerIds', 'provider_ids'],
    ['checkPhoneBlocklisted', 'mobile_phone_blacklisted'],
  ]);

  const payload: models.PatientSearchFiltersPayload = {
    // eslint-disable-next-line @typescript-eslint/camelcase
    org_ids: store.filters.searchSubOrgs
      ? [select('organizations.selected-org-child-ids').value]
      : [select('organizations.selected').value.id],
    ...buildFilterPayload<models.PatientSearchFiltersPayload>(
      store,
      map,
      'patientsSearchFilters',
      ['createdAtFrom', 'createdAtTo'],
    ),
  };

  return payload;
});

export const sortCol = projector<PatientSortCol>(
  'patients.sort-col',
  ({ store }) => store.sortCol,
);

export const sortDir = projector<SortDir>(
  'patients.sort-dir',
  ({ store }) => store.sortDir,
);

export const patientsTable = projector<models.PatientsTable>(
  'patients.table',
  ({ store, getStore }) => {
    const cStore = getStore(capabilitiesStateKey) as CapabilitiesState;
    return store.data.map(d => ({
      createdAt: dateFormatter(
        d.created_at_date,
        cStore.capabilities.international.datetimeFormat,
        'date',
      ),
      patientName: `${d.patient_last_name}, ${d.patient_first_name}`,
      providerName: `${d.provider_first_name} ${d.provider_last_name}`,
      clinicId: d.clinic_patient_code,
      patientStatus: humanReadablePatientStatus(d.patient_status),
      language: d.preferred_language_name || d.preferred_language,
      activeCaregiver: humanReadableActiveCaregiver(d.has_active_caregiver),
      patientId: d.id,
    }));
  },
);
