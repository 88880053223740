import { TableMessage } from './../../shared/empty-table-messages';
import { buildFilterPayload, SortDir, ResponsesSortCol } from '@/shared';
import { dateFormatter } from '../../../../../shared/date-formatter';
import {
  ResponsesFilters,
  ResponsesTable,
  ResponsesSearchFiltersPayload,
} from '../models';
import { responsesProjector } from './projections-map';
import { capabilitiesStateKey } from '@/capabilities/+state/store';
import { CapabilitiesState } from '@conversa/bedazzled/src/models/capabilities-state.interface';

export const responsesFilters = responsesProjector<ResponsesFilters>(
  'responses.filters',
  ({ store }) => store.filters,
);

export const responsesTable = responsesProjector<ResponsesTable>(
  'responses.table',
  ({ store, getStore }) => {
    const cStore = getStore(capabilitiesStateKey) as CapabilitiesState;
    return store.data.map(d => ({
      responseDate: dateFormatter(
        d.response_created_at,
        cStore.capabilities.international.datetimeFormat,
        'dateTime',
      ),
      patientName: `${d.patient_last_name}, ${d.patient_first_name}`,
      clinicPatientCode: d.clinic_patient_code,
      patientId: d.patient_id,
      programModule: d.survey_name,
      question: d.question_text,
      response: d.response_text,
      responseStatus: d.response_status,
      language: d.language,
    }));
  },
);

export const responsesSearchFilters = responsesProjector<
  ResponsesSearchFiltersPayload
>('responses.search-payload', ({ store, select }) => {
  const map = new Map([
    ['activeCaregiver', 'has_active_caregiver'],
    ['programIds', 'survey_ids'],
    ['providerIds', 'provider_ids'],
    ['firstName', 'first_name'],
    ['lastName', 'last_name'],
    ['question', 'question_text'],
    ['clinicPatientCode', 'clinic_patient_code'],
    ['language', 'preferred_language'],
    ['responseStatus', 'colors'],
    ['patientStatus', 'patient_status'],
    ['responsesDateFrom', 'response_date_from'],
    ['responsesDateTo', 'response_date_to'],
    ['showTestPatients', 'show_test_patients'],
  ]);

  const payload: ResponsesSearchFiltersPayload = {
    // eslint-disable-next-line @typescript-eslint/camelcase
    org_id: select('organizations.selected').value.id,
    ...buildFilterPayload<ResponsesSearchFiltersPayload>(
      store,
      map,
      'responsesSearchFilters',
    ),
  };

  return payload;
});

export const tableMessage = responsesProjector<TableMessage>(
  'responses.tableMessage',
  ({ store }) => store.tableMessage,
);
export const count = responsesProjector<number>(
  'responses.count',
  ({ store }) => store.count,
);
export const loading = responsesProjector<boolean>(
  'responses.loading',
  ({ store }) => store.loading,
);
export const sortCol = responsesProjector<ResponsesSortCol>(
  'responses.sort-col',
  ({ store }) => store.sortCol,
);
export const sortDir = responsesProjector<SortDir>(
  'responses.sort-dir',
  ({ store }) => store.sortDir,
);
export const filtersDirty = responsesProjector<boolean>(
  'responses.filters-dirty',
  ({ store }) => store.filtersDirty,
);
export const pageNumber = responsesProjector<number>(
  'responses.page-number',
  ({ store }) => store.page,
);
