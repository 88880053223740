import { axiosWithAuth, isAuthError } from '@/shared/axios-with-auth';
import { createSink } from '@conversa/sink';
import { PatientChartingLoadFailed, PatientChartingMounted } from './events';
import {
  PatientChartDto,
  PatientChartState,
} from '../models/charting.interface';
import { isAxiosError } from '@/shared';
import { handleApiError } from '@/shared/api/set-store-error';
import { PatientDetailUnmounted } from '@/patients/detail/+state/events';
import { patientChartInitialState } from './store';
import { ChartFilterChanged } from './events';
import { filterInputUpdate } from '@/shared';
import { FilterDatesUpdated } from './events';

export const patientChartsRequested = createSink<
  PatientChartState,
  ReturnType<typeof PatientChartingMounted | typeof FilterDatesUpdated>
>({
  sources: [PatientChartingMounted, FilterDatesUpdated],
  async sink({ broadcast, getStore, select, store }) {
    store.loading = true;
    store.error = null;

    const token = getStore('user').accessToken;
    const patientId = select('router.current-patient-id').value;

    if (!patientId) return;

    const { dateFrom, dateTo } = store.filters;

    try {
      const { data } = await axiosWithAuth(token, broadcast).post<
        PatientChartDto
      >(`/api/vista/patients/${patientId}/charting`, {
        filters: {
          // eslint-disable-next-line @typescript-eslint/camelcase
          utc_date_from: dateFrom,
          // eslint-disable-next-line @typescript-eslint/camelcase
          utc_date_to: dateTo,
        },
      });

      store.data.diastolic = data.diastolic;
      store.data.systolic = data.systolic;
    } catch (error) {
      if (!isAxiosError(error)) {
        if (!isAuthError(error)) {
          console.log(error);
        }

        return;
      }

      handleApiError(store, error);

      broadcast(
        PatientChartingLoadFailed({
          error: store.error,
        }),
      );
    } finally {
      store.loading = false;
    }
  },
});

export const clearPatientChartState = createSink<
  PatientChartState,
  ReturnType<typeof PatientDetailUnmounted>
>({
  sources: [PatientDetailUnmounted],
  sink({ store }) {
    store.data = { ...patientChartInitialState.data };
    store.error = null;
    store.loading = false;
    store.filters = { ...patientChartInitialState.filters };
  },
});

export const filterDatesUpdated = createSink<
  PatientChartState,
  ReturnType<typeof FilterDatesUpdated>
>({
  sources: [ChartFilterChanged],
  sink({ broadcast, event, store }) {
    filterInputUpdate({ event, store });
    broadcast(FilterDatesUpdated());
  },
});
