import { createProjector } from '@conversa/sink';
import { ErrorState } from '@conversa/bedazzled/src/models/error-state.interface';
import {
  PatientChartBpData,
  PatientChartData,
  PatientChartOptions,
  PatientChartState,
  PatientChartDataset,
} from '../models';
import {
  patientChartDataInitialStateBP,
  patientChartOptionAnnotationNoData,
  patientChartOptionAnnotationWithData,
  patientChartOptionsInitialStateBP,
} from './store';
import { ChartDateFilters } from '../models';
import { eachDayOfInterval } from 'date-fns';
import { datefnsMask } from '@/shared';
import { capabilitiesStateKey } from '@/capabilities/+state/store';
import { CapabilitiesState } from '@conversa/bedazzled/src/models/capabilities-state.interface';

export interface PatientsDetailChartingProjections {
  'patients.detail.charting.activePatientChartData': PatientChartData;
  'patients.detail.charting.activePatientBpData': PatientChartBpData;
  'patients.detail.charting.activePatientChartOptions': PatientChartOptions;
  'patients.detail.charting.chartDateFilters': ChartDateFilters;
  'patients.detail.charting.isLoading': boolean;
  'patients.detail.charting.error': ErrorState;
}

const projector = createProjector<
  PatientsDetailChartingProjections,
  PatientChartState
>();

const selectedDateRange = (startDate: string, endDate: string): string[] => {
  const dateArray = eachDayOfInterval({
    start: new Date(startDate),
    end: new Date(endDate),
  });

  return dateArray.map(d => d.toISOString());
};

export const activePatientChartData = projector<PatientChartData>(
  'patients.detail.charting.activePatientChartData',
  ({ store }) => {
    const chartData = JSON.parse(
      JSON.stringify(patientChartDataInitialStateBP),
    );

    chartData.labels = selectedDateRange(
      store.filters.dateFrom,
      store.filters.dateTo,
    );
    if (!store.data.diastolic.length && !store.data.systolic.length) {
      chartData.datasets = [];
      return chartData;
    }

    chartData.datasets.forEach((dataset: PatientChartDataset) => {
      switch (dataset.label) {
        case 'Systolic':
          dataset.data = store.data.systolic;
          break;
        case 'Diastolic':
          dataset.data = store.data.diastolic;
          break;
      }
    });

    return chartData;
  },
);

export const activePatientBpData = projector<PatientChartBpData>(
  'patients.detail.charting.activePatientBpData',
  ({ store }) => {
    return store.data;
  },
);

export const activePatientChartOptions = projector<PatientChartOptions>(
  'patients.detail.charting.activePatientChartOptions',
  ({ store, getStore }) => {
    const cStore = getStore(capabilitiesStateKey) as CapabilitiesState;

    patientChartOptionsInitialStateBP.scales.xAxes[0].time.displayFormats.day = datefnsMask(
      cStore.capabilities.international.datetimeFormat,
    );

    patientChartOptionsInitialStateBP.scales.xAxes[0].time.tooltipFormat = datefnsMask(
      cStore.capabilities.international.datetimeFormat,
      true,
    );

    const chartOptions = JSON.parse(
      JSON.stringify(patientChartOptionsInitialStateBP),
    );

    chartOptions.annotation.annotations =
      !store.data.diastolic.length && !store.data.systolic.length
        ? patientChartOptionAnnotationNoData
        : patientChartOptionAnnotationWithData;

    return chartOptions;
  },
);

export const dateFilters = projector<ChartDateFilters>(
  'patients.detail.charting.chartDateFilters',
  ({ store }) => store.filters,
);

export const isLoading = projector<boolean>(
  'patients.detail.charting.isLoading',
  ({ store }) => store.loading,
);

export const errorMessage = projector<ErrorState | undefined>(
  'patients.detail.charting.error',
  ({ store }) => store.error,
);
